// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

// Note for dark mode:
// Uncomment below code and change primary color code to set different primary(i.e. light color of your primary) color in dark mode only.

// Fonts
//
@font-face {
  font-family: 'Instrument';
  font-weight: 400;
  src: url('../fonts/Instrument/InstrumentSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Instrument Medium';
  font-weight: 500;
  src: url('../fonts/Instrument/InstrumentSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Instrument Semi-Bold';
  font-weight: 600;
  src: url('../fonts/Instrument/InstrumentSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Instrument Bold';
  font-weight: 700;
  src: url('../fonts/Instrument/InstrumentSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter Medium';
  font-weight: 500;
  src: url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter Semi-Bold';
  font-weight: 600;
  src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter Bold';
  font-weight: 700;
  src: url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NewBlack Bold';
  src: url('../fonts/NewBlackTypeface-Bold.woff2') format('woff2'),
       url('../fonts/NewBlackTypeface-Bold.woff') format('woff'),
       url('../fonts/NewBlackTypeface-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NewBlack ExtraBold';
  src: url('../fonts/NewBlackTypeface-ExtraBold.woff2') format('woff2'),
       url('../fonts/NewBlackTypeface-ExtraBold.woff') format('woff'),
       url('../fonts/NewBlackTypeface-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

// Font Families
/*User CSS*/
$fill-primary-light: #6969ED;

[data-bs-theme="dark"] {
  --bs-primary: #8458DC !important;
  --bs-primary-rgb: 132, 88, 220 !important; 
  --bs-link-hover-color:#5C33AE !important;
  --bs-link-color:#8458DC;
  --bs-link-color-rgb: 132, 88, 220 !important;
  --bs-link-hover-color-rgb: 92, 51, 174 !important;


  // Button primary
  .btn-primary {
    --bs-btn-bg: var(--#{$prefix}primary);
    --bs-btn-border-color: var(--#{$prefix}primary);
    --bs-btn-hover-bg: var(--#{$prefix}link-hover-color);
    --bs-btn-hover-border-color: var(--#{$prefix}link-hover-color);
    --bs-btn-active-bg: var(--#{$prefix}link-hover-color);
    --bs-btn-active-border-color: var(--#{$prefix}link-hover-color);
    --bs-btn-disabled-bg: var(--#{$prefix}primary);
  }
  .btn-link {
    --bs-link-color: var(--#{$prefix}primary);
    --bs-btn-hover-color: var(--#{$prefix}link-hover-color);
    --bs-btn-active-color: var(--#{$prefix}link-hover-color);
  }
  .btn-outline-primary {
    --bs-btn-color: var(--#{$prefix}primary);
    --bs-btn-border-color: var(--#{$prefix}primary);
    --bs-btn-hover-bg: var(--#{$prefix}primary);
    --bs-btn-hover-border-color: var(--#{$prefix}primary);
    --bs-btn-active-bg: var(--#{$prefix}primary);
    --bs-btn-active-border-color: var(--#{$prefix}primary);
    --bs-btn-disabled-color: var(--#{$prefix}primary);
    --bs-btn-disabled-border-color: var(--#{$prefix}primary);
  }

  .btn-primary-soft{
    color: var(--#{$prefix}primary);
    background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
    &:hover, &:focus{
      color: $white;
      background-color: var(--#{$prefix}primary);
      border-color: var(--#{$prefix}primary);
    }
  }

  // Fill color
  .fill-primary{
    fill: var(--#{$prefix}primary) !important;
  }
}

.fill-primary-light{
  fill: $fill-primary-light;
}

.bg-primary {
  background: $primary;
}

.hero-video-main {
  position: relative;
  overflow: hidden;
  video {
    height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-aspect-ratio: 9/16) { 
  video {
      height: 100vh; /* Volle Höhe des Viewports */
      width: auto; /* Begrenzt die Breite, um Verzerrung zu vermeiden */
  }
}